import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listLoading: false,
  actionsLoading: false,
  requestList: [],
  requestItem: {},
  requestHistory: [],
  error: null,
  totalCount: 0,
  totalPages: 1,
  onlinOrderList: [],
  originalRequestList: [],
  socket: null,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const commonSlice = createSlice({
  name: "common",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    callSuccess: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    createSocket: (state, action) => {
      state.socket = action.payload;
    },
  },
});
