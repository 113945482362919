import axios from "axios";

export const LOGIN_URL = `${process.env.REACT_APP_API_PUBLIC_URL}/v1/admin/login/loginUser`;
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
export const ME_URL = `${process.env.REACT_APP_API_URL}/auth/me`;
export const RENEW_ACCESS_TOKEN =
  process.env.REACT_APP_API_PUBLIC_URL + "v1/auth/renewAccessToken";

export function login(email, password) {
  return axios.post(LOGIN_URL, {
    email,
    userPsw: password,
    trackingPriority: 0,
    frontendAction: "admin.login.loginUser",
  });
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}

// GET =>  renew AccessToken
export function renewAccessToken(refreshToken) {
  return axios.post(RENEW_ACCESS_TOKEN, refreshToken);
}
