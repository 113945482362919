/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useMemo, useEffect } from "react";
import {
  Nav,
  Tab,
  Dropdown,
  OverlayTrigger,
  Tooltip,
  Button,
} from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import SVG from "react-inlinesvg";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../../_partials/dropdowns";
import * as actions from "./_redux/NotificationActions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import BrowserNotification, { showNotification } from "./BrowserNotification";
import { Link } from "react-router-dom";

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

export function UserNotificationsDropdownV2() {
  const [key, setKey] = useState("Alerts");
  const bgImage = toAbsoluteUrl("/media/misc/bg-1.jpg");

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas:
        objectPath.get(uiService.config, "extras.notifications.layout") ===
        "offcanvas",
    };
  }, [uiService]);

  const dispatch = useDispatch();

  const {
    loggedInUserId,
    notificationsList,
    unreadNotificationCount,
    list,
    socket,
  } = useSelector(
    (state) => ({
      loggedInUserId: state.auth.user.userId,
      notificationsList: state.notificationModule.notificationsList,
      unreadNotificationCount: state.notificationModule.unreadNotificationCount,
      list: state.notificationModule.list,
      socket: state.common.socket,
    }),
    shallowEqual
  );

  const userId = loggedInUserId;

  useEffect(() => {
    dispatch(
      actions.unreadNotificationCount({
        loggedInUserId,
        appInfo: "ADMIN",
      })
    );
  }, []);

  const listNotificationData = () => {
    dispatch(
      actions.listNotifications({
        page: 1,
        pageSize: 50,
        loggedInUserId,
        appInfo: "ADMIN",
      })
    );
    dispatch(actions.updateUnreadNotificationCount(0));
  };

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    socket?.off("notificationService")?.on(
      "notificationService",
      (notificationMessage) => {
        dispatch(
          actions.unreadNotificationCount({
            loggedInUserId,
            appInfo: "ADMIN",
          })
        );
        let title = notificationMessage.notfyHead;
        let content = notificationMessage.message;
        showNotification(title, content);
      },
      []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  return (
    <>
      {layoutProps.offcanvas && (
        <div className="topbar-item">
          <div
            className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-primary"
            id="kt_quick_notifications_toggle"
          >
            <span className="svg-icon svg-icon-xl svg-icon-primary">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")} />
            </span>
            <span className="pulse-ring"></span>
          </div>
        </div>
      )}
      {!layoutProps.offcanvas && (
        <Dropdown drop="down" alignRight onClick={() => listNotificationData()}>
          <Dropdown.Toggle
            as={DropdownTopbarItemToggler}
            id="kt_quick_notifications_toggle"
          >
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="user-notification-tooltip">
                  User Notifications
                </Tooltip>
              }
            >
              <div
                className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-primary "
                id="kt_quick_notifications_toggle"
              >
                <span className="svg-icon svg-icon-xl svg-icon-primary">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")}
                  />
                </span>
                {unreadNotificationCount && unreadNotificationCount !== 0 ? (
                  <>
                    <span className="pulse-ring"></span>
                    <span className="position-absolute badge badge-circle badge-sm badge-success">
                      {unreadNotificationCount && unreadNotificationCount}
                    </span>
                  </>
                ) : null}
              </div>
            </OverlayTrigger>
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">
            <form>
              {/** Head */}
              <div
                className="d-flex flex-column pt-8 bgi-size-cover bgi-no-repeat rounded-top"
                style={{ backgroundImage: `url(${bgImage})` }}
              >
                <h4 className="d-flex flex-center rounded-top">
                  <span className="text-white">Notifications</span>
                  {/* <span className="btn btn-text btn-success btn-sm font-weight-bold btn-font-md ml-2">
                    {unreadNotificationCount && unreadNotificationCount}
                  </span> */}
                </h4>

                <Tab.Container defaultActiveKey={key}>
                  {/* <Nav
                    as="ul"
                    className="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-line-transparent-white nav-tabs-line-active-border-success mt-3 px-8"
                    onSelect={(_key) => setKey(_key)}
                  >
                    <Nav.Item className="nav-item" as="li">
                      <Nav.Link
                        eventKey="Alerts"
                        className={`nav-link show ${
                          key === "Alerts" ? "active" : ""
                        }`}
                      >
                        Alerts
                      </Nav.Link>
                    </Nav.Item>
                  </Nav> */}

                  <Tab.Content className="tab-content">
                    <Tab.Pane eventKey="Alerts" className="p-8">
                      <PerfectScrollbar
                        options={perfectScrollbarOptions}
                        className="scroll pr-7 mr-n7"
                        style={{ maxHeight: "300px", position: "relative" }}
                      >
                        <div className="d-flex align-items-center mb-6">
                          {/* <div className="symbol symbol-40 symbol-light-primary mr-5">
                            <span className="symbol-label">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Home/Library.svg"
                                )}
                                className="svg-icon-lg svg-icon-primary"
                              ></SVG>
                            </span>
                          </div> */}
                          {list === true ? (
                            <div className="d-flex justify-content-center align-item-center my-10 mx-auto">
                              <div className="spinner spinner-lg spinner-success" />
                            </div>
                          ) : (
                            <div className="d-flex flex-column font-weight-bold">
                              {notificationsList &&
                              notificationsList.length > 0 ? (
                                <>
                                  {notificationsList &&
                                    notificationsList.map((item, index) => (
                                      <div
                                        className="d-flex flex-column"
                                        key={index}
                                      >
                                        <a
                                          href="#"
                                          className="text-dark text-hover-primary mb-1 font-size-lg"
                                        >
                                          {item?.metaData?.notfyHead}
                                        </a>
                                        <span className="text-muted mb-4">
                                          <span>{item?.metaData?.message}</span>
                                        </span>
                                      </div>
                                    ))}
                                </>
                              ) : (
                                <span className="text-muted mb-4">
                                  <span>No Notifications</span>
                                </span>
                              )}
                            </div>
                          )}
                        </div>
                      </PerfectScrollbar>
                    </Tab.Pane>

                    <div className="d-flex justify-content-center align-items-center mb-3">
                      <Link
                        to="/notifications/list"
                        className="btn btn-light btn-text-dark btn-hover-text-dark font-weight-bold"
                      >
                        View All
                      </Link>
                    </div>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </form>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
}
