import * as requestFromServer from "./commonCrud";
import { commonSlice, callTypes } from "./commonSlice";

const { actions } = commonSlice;

export const getSignedUrl = (signedUrlDataForCreation) => (dispatch) => {
  let { file, ...rest } = signedUrlDataForCreation;

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getSignedUrl({ ...rest })
    .then((response) => {
      requestFromServer
        .signedUpload(response?.data?.data.signed_request, file)
        .then((res) => {
          dispatch(actions.callSuccess({ callType: callTypes.action }));
        })
        .catch((err) => {
          dispatch(actions.catchError({ callType: callTypes.action }));
          // console.log("err........", err);
        });
    })
    .catch((error) => {
      dispatch(actions.catchError({ callType: callTypes.action }));
      return error;
    });
};

export const createSocket = (queryParams) => (dispatch) => {
  dispatch(actions.createSocket(queryParams));
};

export const clearSignedUrlFetched = () => (dispatch) => {
  dispatch(actions.signedUrlFetched(""));
};