import React, { Suspense, lazy, useEffect } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen } from "../_metronic/layout";
import { io } from "socket.io-client";
import { createSocket } from "./modules/_redux/commonActions";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
// import {ChatModule} from "./modules/ChatModule/ChatModule";

// import { BuilderPage } from "./pages/BuilderPage";
// import { MyPage } from "./pages/MyPage";
// import { DashboardPage } from "./pages/DashboardPage";

// const GoogleMaterialPage = lazy(() =>
//   import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
// );
// const ReactBootstrapPage = lazy(() =>
//   import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
// );

// const ECommercePage = lazy(() =>
//   import("./modules/ECommerce/pages/eCommercePage")
// );
// const UserProfilepage = lazy(() =>
//   import("./modules/UserProfile/UserProfilePage")
// );
const InvitationPage = lazy(() =>
  import("./modules/InviteManagement/pages/smsInviteePage")
);
const CampaignPage = lazy(() => import("./modules/campaign/campaignsPage"));

const NotificationPage = lazy(() =>
  import(
    "./modules/Notification/NotificationPage"
  )
);
const ApplicationPage = lazy(() => import("./modules/campaignApplication/campaignApplicationPage"));

const ChatModule = lazy(() => import("./modules/ChatModule/ChatModule"));

export default function BasePage() {
  const dispatch = useDispatch();
  var socket = io(process.env.REACT_APP_SOCKET_SERVER_URL, {
    transports: ["websocket"],
  });

  const { userId } = useSelector(
    (state) => ({
      userId: state?.auth?.user?.userId,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(createSocket(socket));
    if (userId) {
      socket.connect();
      socket.emit("create-room", userId);
      socket.emit("joinRoom", userId, socket.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/invitation" />
        }
        {/* <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/builder" component={BuilderPage} />
        <ContentRoute path="/my-page" component={MyPage} />
        <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} /> */}
        <Route path="/invitation" component={InvitationPage} />
        <Route path="/campaigns" component={CampaignPage} />
        <Route path="/notifications" component={NotificationPage} />
        <Route path="/applications" component={ApplicationPage} />
        <Route path="/messagings" component={ChatModule} />
        {/* <Route path="/e-commerce" component={ECommercePage} />
        <Route path="/user-profile" component={UserProfilepage} />
        <Redirect to="error/error-v1" /> */}
      </Switch>
    </Suspense>
  );
}
