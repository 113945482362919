import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listLoading: false,
  actionsLoading: false,
  error: null,
  totalCount: 0,
  totalPages: 1,
  entities: null,

  applnTotalCount: 0,
  applications: null,

  applicationContents: null,
  contentCount: 0,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const campaignSlice = createSlice({
  name: "campaign",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    callSuccess: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    campaignsFetched: (state, action) => {
      let { campaigns, totalCount } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = campaigns;
      state.totalCount = totalCount;
    },

    applicationsFetched: (state, action) => {
      let { applications, totalCount } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.applications = applications;
      state.applnTotalCount = totalCount;
    },

    applicationContentFetched: (state, action) => {
      let { content, contentCount } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.applicationContents = content;
      state.contentCount = contentCount;
    },

    campaignDetailsFetched: (state, action) => {
      let { campaignDetails } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.campaignDetails = campaignDetails;
    },
  },
});
