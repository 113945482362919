import * as requestFromServer from "./NotificationCrud";
import { notificationSlice, callTypes } from "./NotificationSlice";

const { actions } = notificationSlice;

export const listNotifications = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getNotifications(queryParams)
    .then((response) => {
      const { total: totalCount, rows: notificationsList } = response.data.data;
      dispatch(actions.notificationsFetched({ totalCount, notificationsList }));
    })
    .catch((error) => {
      // console.error("Can't find notifications");
      error.clientMessage = "Can't find notifications";
      dispatch(
        actions.notificationsFetched({ totalCount: 0, notifications: null })
      );
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const unreadNotificationCount = (queryParams) => (dispatch) => {
  return requestFromServer
    .getUnreadNotificationCount(queryParams)
    .then((response) => {
      dispatch(
        actions.unreadNotificationCountFetched(response.data.data.count)
      );
    })
    .catch((error) => {
      console.error("Can't find notifications");
      // error.clientMessage = "Can't find notification count";
      // dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const clearNotification = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .clearNotification(queryParams)
    .then((response) => {
      dispatch(actions.clearNotificationSlice());
      dispatch(actions.callSuccess({ callType: callTypes.list }));
    })
    .catch((error) => {
      // console.error("can't clear");
      error.clientMessage = "Can't clear notification";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const updateNotificationList = (incomingData) => (dispatch) => {
  dispatch(actions.updateIncomingNotificationList(incomingData));
};

export const updateUnreadNotificationCount = (incomingData) => (dispatch) => {
  // if (incomingData) {
    dispatch(actions.updateIncomingUnreadNotificationCount(incomingData));
  // }
};

export const sendNotification = (queryParams) => (dispatch) => {
  return requestFromServer
    .sendAppNotification(queryParams)
    .then((response) => {

    })
    .catch((error) => {
      // console.error("can't clear");
      error.clientMessage = "Can't Send notification";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
