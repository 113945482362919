import axios from "axios";

export const GET_SIGNED_URL = "v1/image/signedUrl";

// GET =>  get signed Url
export function getSignedUrl(signedUrl) {
  return axios.post(GET_SIGNED_URL, {
    ...signedUrl,
    frontendAction: "ImageSignedUrl",
    trackingPriority: 0,
  });
}

//file upload to s3
export function signedUpload(url, file) {
  let config = {
    headers: {
      "Content-type": file.type,
    },
  };
  return axios.put(url, file, config);
}
