import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { customersSlice } from "../app/modules/ECommerce/_redux/customers/customersSlice";
import { productsSlice } from "../app/modules/ECommerce/_redux/products/productsSlice";
import { remarksSlice } from "../app/modules/ECommerce/_redux/remarks/remarksSlice";
import { specificationsSlice } from "../app/modules/ECommerce/_redux/specifications/specificationsSlice";
import { creatorInviteeSlice } from "../app/modules/InviteManagement/_redux/creator/creatorInviteSlice";
import { brandInviteeSlice } from "../app/modules/InviteManagement/_redux/brand/brandInviteSlice";
import { commonSlice } from "../app/modules/_redux/commonSlice";
import { campaignSlice } from "../app/modules/campaign/_redux/campaignSlice";
import { notificationSlice } from "../_metronic/layout/components/extras/dropdowns/notifications/_redux/NotificationSlice";
import {applicationSlice } from "../app/modules/campaignApplication/_redux/campaignApplicationSlice"
import { ChatModuleSlice } from "../app/modules/ChatModule/_redux/ChatModuleSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  customers: customersSlice.reducer,
  products: productsSlice.reducer,
  remarks: remarksSlice.reducer,
  specifications: specificationsSlice.reducer,
  creatorInvitation: creatorInviteeSlice.reducer,
  brandInvitation: brandInviteeSlice.reducer,
  common: commonSlice.reducer,
  campaign: campaignSlice.reducer,
  notificationModule:notificationSlice.reducer,
  campaignApplication:applicationSlice.reducer,
  chatModule: ChatModuleSlice.reducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
