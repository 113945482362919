import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    listLoading: false,
    actionsLoading: false,
    error: null,
    totalCount: 0,
    totalPages: 1,
    entities: null
};

export const callTypes = {
    list: "list",
    action: "action",
};

export const applicationSlice = createSlice({
    name: "application",
    initialState: initialState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        callSuccess: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        applicationsFetched: (state, action) => {
            let { totalCount, applications ,totalPages } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.entities = applications;
            state.totalCount = totalCount;
            state.totalPages = totalPages;
          },
    },
});